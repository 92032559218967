
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
// bootstrap
// slick-carousel
// matchHeight
// tooltipster
// fullcalendar
// tether
// flickity

import $ from 'jquery';
import {tooltipster} from 'tooltipster';
import {matchheight} from 'matchheight';
import {fullcalendar} from 'fullcalendar';
import {tether} from 'tether';
import {flickity} from 'flickity';

// window['$'] = window['jquery'] = $;
// window['jQuery'] = $;
require('jquery-ui/ui/widgets/datepicker.js');
require('../../node_modules/jquery-match-height/dist/jquery.matchHeight');
window['Tether'] = require('../../node_modules/tether/dist/js/tether');
window['bootstrap'] = require('../../node_modules/bootstrap/dist/js/bootstrap');

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        var startY = 200;
        var width = $('html').outerWidth();

        function checkY() {
          if (width > 544) {
            if ($(window).scrollTop() > startY) {
              $('.navbar').fadeIn(2000).css('background', 'rgba(246,144,78,1)');
            } else {
              $('.navbar').fadeIn(2000).css('background', 'rgba(0,0,0,0)');
            }
          }
        }

        checkY();

        $(window).scroll(function() {
          checkY();
        });

        // Dropdown Menu Fade
        $(".navbar .nav-item").hover(
          function() {
            $('.dropdown-menu', this).stop().fadeIn("slow");
            $(this).addClass('active');
          },
          function() {
            $('.dropdown-menu', this).stop().hide();
            $(this).removeClass('active');
          }
        );

        // Make elementor elements with a link be entirely clickable
        $('.elementor-column').each(function() {
          var link = $(this).find('.elementor-heading-title a');
          if (link !== undefined) {
            $(link).closest('.elementor-column').addClass('link');
          }
        });

        $('.elementor-column').on('click', function() {
          var link = $(this).find('.elementor-heading-title a').attr('href');
          if (link !== undefined) {
            window.location.href = link;
          }
        });

        $('.js-match-height').matchHeight();

        $('.js-show-listings').on("click", function(e) {
          e.preventDefault();
          $('.listings').slideToggle();
        });

        // Search dropdown
        $('.am2-dropdown').click(function(e) {
          e.preventDefault();
          $('.am2-dropdown-form').slideToggle(200);
        });
         $('.am2-close').click(function(e) {
          $('.am2-dropdown-form').slideUp(200);
        });

        // Listings stuff
        function refresh_listings_content() {
          var loader = '<div class="preloader">Searching for listings...</div>';

          var search_term = jQuery('.listings__search').val();
          var category = jQuery("#listing_filter_form input[name='category[]']:checked").map(function(){
            return jQuery(this).val();
          }).get();
          var neighbourhood = jQuery("#listing_filter_form select[name='listing_neighbourhood']").val();
          // var activity = jQuery("#listing_filter_form select[name='listing_activity']").val();
          jQuery.ajax({
            type : "post",
            dataType : "json",
            url : surrey_listings.ajaxurl,
            data : {
              action: "listings_handle_search",
              search_term : search_term,
              security: surrey_listings.ajax_nonce,
              category: category,
              neighbourhood: neighbourhood,
              // activity: activity
            },
            beforeSend: function( data ) {
              $('#listings-content').empty().html(loader);
            },
            success: function(response) {
              if(response.type == "success") {
                $("#listings-content").empty().html(response.output);
              }
            }
          });
        }

        if ($('#listing_filter_form')) {
          // Filters toggle
          $('.filter__toggle').on('click', function(e) {
            e.preventDefault();
            $(this).toggleClass('filter__toggle--active');
            $('.filter__list').toggleClass('filter__list--active');
          });

          var listing_timeout;
          $('.listings__search').on('keyup', function(){
            clearTimeout(listing_timeout);
            listing_timeout = setTimeout(function(){
              refresh_listings_content();
            }, 500);
          });
          $('.filter__select').on('change', function(){
            clearTimeout(listing_timeout);
            listing_timeout = setTimeout(function(){
              refresh_listings_content();
            }, 500);
          });
          $('.filter__checkbox').on('change', function(){
            // if ($('.filter__checkbox:checked').length === 0) {
            //   $('#checkbox_all').prop('checked', true);
            // } else if ($('.filter__checkbox:checked').length > 1 && $('#checkbox_all:checked').length > 0) {
            //   $('#checkbox_all').prop('checked', false);
            // } else if ($('#checkbox_all:checked').length > 0) {
            //   $('.filter__checkbox:checked').prop('checked', false);
            //   $('#checkbox_all').prop('checked', true);
            // }
            clearTimeout(listing_timeout);
            listing_timeout = setTimeout(function(){
              refresh_listings_content();
            }, 500);
          });
        }

        // Calendar stuff
        function addClassByDate(date) {
          var dataAttr = getDataAttr(date);
          $("[data-date='" + dataAttr + "']").addClass("hasEvent");
        }

        function getDataAttr(date) {
          return date.getFullYear() + "-" + ((date.getMonth()+1).toString().length === 2 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)) + "-" + (date.getDate().toString().length === 2 ? date.getDate() : "0" + date.getDate());
        }

        function highlightEventDays() {
          if(surrey_events_object !== null && surrey_events_object.length > 0) {
            for (var i = 0; i < surrey_events_object.length; i++) {
              var evStartDate = new Date(surrey_events_object[i].start),
                evFinishDate = new Date(surrey_events_object[i].end);
              if (surrey_events_object[i].end) {
                while (evStartDate <= evFinishDate) {
                  addClassByDate(evStartDate);
                  evStartDate.setDate(evStartDate.getDate() + 1);
                }
              } else {
                addClassByDate(evStartDate);
              }
            }
          }
        }

        let calendar_args;

        if ($('#js-calendar').length) {
          calendar_args = {
            header: {
              left: 'prev',
              center: 'title',
              right: 'next'
            },
            editable: false,
            firstDay: 7,
            timezone: 'America/Vancouver',
            dayNamesShort: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            eventClick:  function(event) {
              window.open(event.permalink);
            },
            eventAfterAllRender: highlightEventDays,
            eventLimit: true,
            eventLimitClick: function(cellInfo) {
              // console.log(cellInfo);
              return "popover";
            },
            aspectRatio: 0.66,
            events: surrey_events_object,
            eventMouseover: function(event, jsEvent, view) {
              $(this).not('.tooltipstered').tooltipster({
                debug: false,
                contentAsHTML: true,
                theme : 'tooltipster-pink',
                trigger: 'hover',
                interactive: true,
                repositionOnScroll: true,
                // side: ['bottom', 'right', 'left', 'top'],
                trackOrigin: true,
                delay: [350,100],
                content:$('<div class="cal-popover event-detail"><div id="popover-head" class="event-detail__head"><div class="event-detail__date"><p class="event-detail__day">' + event.event_day + '</p><p class="event-detail__month">'+event.event_month+'</p></div><div class="event-detail__time">'+event.event_time_start_end+'</div><h5 class="event-detail__venue">'+event.location+'</h5></div><div id="popover-content" class="event-detail__content"><h3 class="event-detail__title">'+event.title+'</h3><p class="event-detail__summary">'+event.content+'</p><a href="'+event.permalink+'" class="event-detail__see-more">See More</a></div></div>')
              }).tooltipster('open');
            },
            eventMouseout: function(event, jsEvent, view) {
              // $(this).tooltipster('close');
            }
          };
          $('#js-calendar').fullCalendar(calendar_args);
        }

        // Neighbourhood Map

        // https://gist.github.com/jeremejazz/9407568
        // Get the center ... copy paste!
        function polygonCenter(poly) {
          var lowx,
              highx,
              lowy,
              highy,
              center_x,
              center_y,
              lats = [],
              lngs = [],
              vertices = poly.getPath();

          for ( var i = 0; i < vertices.length; i++ ) {
            lngs.push(vertices.getAt(i).lng());
            lats.push(vertices.getAt(i).lat());
          }

          lats.sort();
          lngs.sort();
          lowx = lats[0];
          highx = lats[vertices.length - 1];
          lowy = lngs[0];
          highy = lngs[vertices.length - 1];
          center_x = lowx + ((highx-lowx) / 2);
          center_y = lowy + ((highy - lowy) / 2);
          return (new google.maps.LatLng(center_x, center_y));
        }

        if ($('.neighbourhoods-map').length) {
          var nbhd_map_id = $('.neighbourhoods-map').find('.wpgmza_map').attr('id');
          nbhd_map_id = nbhd_map_id.replace('wpgmza_map_','');

          $.fn.matchHeight._afterUpdate = function(event, groups) {
            // resize map after neighbourhood bppd;es are fjdkmatched height
            google.maps.event.trigger(MYMAP[nbhd_map_id].map, 'resize');
          };
        }

        $('figure.neighbourhood a').hover(function(){
          var polygon_id = $(this).data('id');
          var polygon = WPGM_Path_Polygon[polygon_id];

          // Trigger click event on polygon with center of polygon as location
          google.maps.event.trigger(polygon, 'click', {
            latLng: polygonCenter(polygon)
          });
        }, function(){
          // Clear the polygon infowindow and hover colour
          var polygon_id = $(this).data('id');
          var polygon = WPGM_Path_Polygon[polygon_id];

          // Close Info Window
          infoWindow_poly[polygon_id].close();

          // Trigger Mouse Out Event
          google.maps.event.trigger(polygon, "mouseout", {"vertex": 0});
        });

        // Listings Map
        var $map = $('.listings-map');
        if ($map.length > 0) {
          if (!(typeof window.google === 'object' && window.google.maps)) {
            console.error('Google Maps API is not loaded, cannot display map.');
          } else {
            if (typeof window.surrey_listings !== 'undefined' && Array.isArray(window.surrey_listings.listings) && window.surrey_listings.listings.length) {
              var circleIcon = {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: '#f6904e',
                fillOpacity: 1.0,
                strokeColor: '#ffffff',
                strokeWeight: 1,
                scale: 7,
              };

              // The almighty infowindow
              var infowindow = new window.google.maps.InfoWindow({
                maxWidth: 400,
              });

              // Defaults to middle-ish of Surrey
              var center = new window.google.maps.LatLng(49.116599, -122.843958);

              // Set up map
              var map = new window.google.maps.Map($map[0], {
                zoom: 11,
                minZoom: 9,
                center: center,
                fullscreenControl: false,
                mapTypeControl: false,
                rotateControl: false,
                streetViewControl: false,
                scaleControl: false,
              });

              var communityMarkers = [];

              $.each(window.surrey_listings.listings, function(key, listing) {
                var markerPosition = new window.google.maps.LatLng(listing.address.lat, listing.address.lng);

                // Create the marker but don't assign it to the map
                var marker = new window.google.maps.Marker({
                  map: map,
                  position: markerPosition,
                  title: listing.title,
                  icon: circleIcon,
                  animation: window.google.maps.Animation.DROP,
                });

                marker.addListener('click', function(event) {
                  var content = '<div class="iw-listing">';

                  if (listing.thumbnail) {
                    content += '<div class="iw-listing-img"><img src="' + listing.thumbnail + '" class="img-fluid"></div>';
                  }

                  content += '<div class="iw-listing-content"><h4><a href="' + listing.link + '" target="_blank" rel="noopener">' + listing.title + '</a></h4>';

                  if (listing.phone) {
                    content += 'Phone: <a href="tel:' + listing.phone + '">' + listing.phone + '</a>';
                  }

                  content += '</div></div>';

                  infowindow.setContent(content);
                  infowindow.open(map, marker);
                  if (event) {
                    event.stopPropagation; // jshint ignore:line
                  }
                });

                communityMarkers.push(marker);
              });

              map.addListener('click', function() {
                infowindow.close();
              });
            }
          }
        }

        var $appbar         = $('.appbar');
        var $navdrawer      = $('.navigation-drawer');
        var $search_field   = $('.search-field');
        var $html_body      = $('html');
        
        if( !!$appbar.length && !!$navdrawer.length ) {
            var $app_bar_primary = $('.appbar-menu--primary');
            $appbar.on('click', '.nav-toggle,.menu-link--search', function(e){
                
                var obj = $( e.target );

                if( obj.hasClass('nav-toggle') ) {
                    e.preventDefault();
                    e.stopImmediatePropagation();

                    window.requestAnimationFrame(function(){
                        $navdrawer.toggleClass('navigation-drawer--active');
                        setTimeout(function(){
                            $navdrawer.addClass('navigation-drawer--in');
                        },120);
                        $html_body.addClass('no-scroll');
                    });

                    return false;
                }

                if( obj.hasClass('menu-link--search') && !!$search_field.length ) {
                    
                    if( !$search_field.hasClass('search-field--active') ) {
                        e.preventDefault();
                        e.stopImmediatePropagation();

                        window.requestAnimationFrame(function(){
                            $app_bar_primary.addClass('search-field--active');
                            $search_field.addClass('search-field--active');
                        });
    
                        return false;
                    }else{
                        var $input = $('input[name="s"]', $search_field);
                        if( !$input.val() ) {
                            e.preventDefault();
                            e.stopImmediatePropagation();
    
                            window.requestAnimationFrame(function(){    
                                $app_bar_primary.removeClass('search-field--active');
                                $search_field.removeClass('search-field--active');
                            });
                            return false;
                        }
                    }
                }

            });

            $navdrawer.on('click', function(e){
                var obj = $( e.target );
                if( obj.hasClass('drawer-toggle') ) {
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    window.requestAnimationFrame(function(){
                        $navdrawer.removeClass('navigation-drawer--in');
                        
                        setTimeout(function(){
                            $navdrawer.removeClass('navigation-drawer--active');
                        },320);
    
                        $html_body.removeClass('no-scroll');
                    });
                    return false;
                }
            });

            if( !!window.matchMedia('(min-width: 1366px)').matches ) {
                var $scroll_max = 577;

                var fnThrottleScroll = function(fn, wait) {
                    var time = Date.now();
                    return function() {
                        if ((time + wait - Date.now()) < 0) {
                        fn();
                        time = Date.now();
                        }
                    };
                };

                $(window).on('scroll', fnThrottleScroll(function(e) {
                    window.requestAnimationFrame(function(){
                        if( window.pageYOffset >= $scroll_max) {
                            $appbar.addClass('appbar--compact');
                        }else{
                            $appbar.removeClass('appbar--compact');
                        }
                    });

                }, 100));
               
            }            
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired           
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        let checkVideo = function() {
          var bannerVideo = $('#banner-video');
          // Check because we may have previously removed the video
          if (bannerVideo.length) {
            var inlineVideo = true;

            if (navigator.userAgent.match(/CriOS/) && 'playsInline' in document.createElement('video')) {
              // Chrome on iOS supports this now
              inlineVideo = true;
            } else if (navigator.userAgent.match(/CriOS/)) {
              // But not older versions
              inlineVideo = false;
            } else if (navigator.userAgent.match(/(\(iPod|\(iPhone|\(iPad)/) && 'playsInline' in document.createElement('video')) {
              // This should be iOS 10 devces
              inlineVideo = true;
            } else if (navigator.userAgent.match(/(\(iPod|\(iPhone|\(iPad)/)) {
              // This should be iOS devices less than iOS 10
              inlineVideo = false;
            }

            if (!inlineVideo) {
              // Remove it, revealing the image
              bannerVideo.remove();
            } else {
              $('.hero__caption').delay(10000).fadeOut('slow');
            }
          }
        };

        checkVideo();

        // Check video stuff on resize
        $(window).on('resize', checkVideo);

        $('.hero__arrow').delay(10000).fadeIn('slow');

        $('.hero__arrow').on('click', function(e) {
          var offset_top = $('#' + $(this).data('section')).offset().top;
          if ( $(window).width() > 768) {
            offset_top -= $('.navbar').height();
          }

          $("html, body").animate({
            scrollTop: offset_top
          }, 1000);
        });

        // $('.stories-slider').slick({
        //   infinite: true,
        //   slidesToShow: 5,
        //   swipeToSlide: true,
        //   draggable: false,

        //   arrows: false,
        //   autoplay: false,
        //   autoplaySpeed: 2000,

        //   responsive: [{
        //     breakpoint: 1024,
        //     settings: {
        //       slidesToShow: 2,
        //     }
        //   }]
        // });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

(function(jq){
    var $ = jq;
    $(function(){
        // Get total width of tags in tag carousel
        function getTagWidths() {
            var width = 0;
            
            $('.tag-carousel .tag-container').each(function () {
                width += $(this).outerWidth(true); 
            });
            
            return width;
        }

        function initFlickity( obj ) {
            var initial = 0;      
            var $tagCarousel = obj;
            
            
            var tagCarouselWidth = $tagCarousel.width();
            var tagWidths = getTagWidths();
            
            if ( $('.viewing-archive') ) {
                initial = $('.viewing-archive').parent('.carousel-cell').index();
            }

            if ( tagWidths > tagCarouselWidth ) {

                $tagCarousel.flickity({
                    wrapAround: true,
                    pageDots: false,
                    groupCells: true,
                    selectCell: initial
                });
                
            } else {
                if ( $tagCarousel.hasClass('flickity-enabled') ) {
                    $tagCarousel.flickity('destroy');
                }
            }
        }        

        // Initialise Tag Carousel if tags width is greater than container width
        setTimeout(function(){
            initFlickity( $('.tag-carousel') );
        }, 1500);
    });
})(jQuery);
